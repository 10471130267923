@keyframes lds-rolling {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes lds-rolling {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.ldsRolling {
  width: 200px !important;
  height: 200px !important;
  -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
  transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
  margin: 0 auto;

  div,
  div:after {
    position: absolute;
    width: 80px;
    height: 80px;
    border: 4px solid #03a9f4;
    border-top-color: transparent;
    border-radius: 50%;
  }

  div {
    -webkit-animation: lds-rolling 0.8s linear infinite;
    animation: lds-rolling 0.8s linear infinite;
    top: 100px;
    left: 100px;

    &:after {
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }
}

.ldsRollingSmall {
  width: 70px !important;
  height: 70px !important;
  -webkit-transform: translate(-50px, -50px) scale(1) translate(35px, 35px);
  transform: translate(-50px, -50px) scale(1) translate(35px, 35px);
  margin: 0 auto;

  div,
  div:after {
    width: 40px;
    height: 40px;
  }

  div {
    top: 50px;
    left: 50px;
  }
}
